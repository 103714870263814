.addItem{
 width: 50%;
 padding-bottom: 3rem;
 line-height: 30px;
 font-size: 1.1rem;
}
.itemTitle{
 color: rgb(var(--darkBlue));
 font-size: 1.25rem;
 letter-spacing: 1px;
}
.itemDesc{
 padding-bottom: 1.5rem;
}

@media(min-width: 1200px) and (max-width: 1600px){
 .addItem{

 }
}
@media(min-width: 768px) and (max-width: 1200px){
 .addItem{
  width: 100%;
 }
}
@media(max-width: 768px){
 .addItem{
  width: 100%;
 }
}