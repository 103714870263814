@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,800&display=swap);
:root {
  /* --blue: #3EB7DE; */
  --blue: 62, 183, 222;
  --darkBlue: 51, 102, 128;
  --orange: 255, 153, 119;
  --medGrey: 125, 125, 125;
  --lightGrey: 225, 225, 225;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}
section {
  padding: 0 0 25vh 0;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
a {
  text-decoration: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
a:hover {
  color: rgb(225, 225, 225);
  color: rgb(var(--lightGrey));
}
.light {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: 800;
}
.orange {
  color: rgb(255, 153, 119);
  color: rgb(var(--orange));
}
.darkBlue {
  color: rgb(51, 102, 128);
  color: rgb(var(--darkBlue));
}
.lightGrey {
  color: rgb(225, 225, 225);
  color: rgb(var(--lightGrey));
}
.medGrey {
  color: rgb(125, 125, 125);
  color: rgb(var(--medGrey));
}
.white {
  color: white;
}
.text-align-end {
  text-align: end;
}
h1 > .hr {
  display: none;
}
.hr {
  padding-top: 1rem;
  display: block;
  width: 50%;
  display: block;
  border-bottom: 1px solid rgb(255, 153, 119);
  border-bottom: 1px solid rgb(var(--orange));
}
.float_right {
  float: right;
}
.container {
  max-width: 75%;
  margin: 0 auto;
  padding-top: 50px;
}
.clearFix {
  clear: both;
}
.shadowBox {
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 99;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hidden {
  display: none;
}
.show {
  display: flex;
}

.animationIn {
  opacity: 1;
}
.animationOut {
  opacity: 0;
}

@media (max-width: 1200px) {
  section {
    padding: 0 0 10vh 0;
  }
  h1 > .hr {
    display: block;
  }
}

#hamburger.open span {
  opacity: 0;
  left: 70px;
}
#xicon.open span {
  opacity: 1;
  top: 50%;
  left: 0;
}
#xicon.open span:nth-child(1) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
#xicon.open span:nth-child(2) {
  -webkit-transition-delay: 0.35s;
          transition-delay: 0.35s;
}

nav {
  background: white;
  width: 100%;
  position: fixed;
  z-index: 1010;
  justify-content: center;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;
  overflow: hidden;
  border-bottom: 1px solid rgb(var(--lightGrey));
  box-shadow: 0 0 15px rgba(var(--blue), 0.25);
  box-sizing: border-box;
  padding: 10px;
  display: flex;
}
nav > ul > li {
  display: inline;
  padding: 0 25px;
  cursor: pointer;
  font-size: 0.9rem;
}
nav > ul > li:hover {
  color: rgba(var(--orange));
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
#nav_navImage__2vtmu {
  position: relative;
  left: 10px;
  cursor: pointer;
}
.nav_toggle__eOURL {
  display: none;
}
.nav_xicon__1cQRl {
  width: 28px;
  height: 28px;
  cursor: pointer;
  position: absolute;
  margin: 0 auto;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  float: right;
  overflow: hidden;
}
.nav_xicon__1cQRl span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: rgba(var(--orange));
  border-radius: 2px;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  top: -50%;
}
.nav_xicon__1cQRl span:nth-child(2) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.nav_xicon__1cQRl span:nth-child(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -100%;
}
.nav_xicon__1cQRl span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 100%;
}
.nav_hamburger__13yXa {
  width: 28px;
  height: 28px;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  float: right;
}
.nav_hamburger__13yXa span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: rgba(var(--orange));
  border-radius: 2px;
  opacity: 1;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  left: 0;
}

.nav_hamburger__13yXa span:nth-child(1) {
  top: 0px;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

.nav_hamburger__13yXa span:nth-child(2) {
  top: 8px;
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}

.nav_hamburger__13yXa span:nth-child(3) {
  top: 16px;
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

.nav_section__34XNR {
  margin: 0 auto;
  padding: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  max-width: 75%;
  text-align: center;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  nav {
    display: block;
  }
  .nav_section__34XNR {
    display: block;
  }
  .nav_section__34XNR > li:first-of-type {
    padding-top: 20px;
  }
  .nav_toggle__eOURL {
    right: 10px;
    float: right;
    position: relative;
    display: block;
    top: 5px;
  }
  .nav_section__34XNR {
    clear: both;
    padding: 0 0 0 50px;
    margin: 0;
    overflow: hidden;
    vertical-align: middle;
    height: 0px;
  }
  .nav_hamburger__13yXa {
    text-align: center;
    float: right;
    display: block;
  }
  nav > ul > li {
    display: block;
    text-align: left;
    padding-right: 0;
    opacity: 1;
    padding-bottom: 10px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    margin-left: -40px;
  }
}

.header_mainHero__A533U {
  height: 100%;
  position: relative;
}
.header_mainHero__A533U > .header_imageOverlay__3ufPJ {
  width: 100%;
  height: 110%;
}
.header_mainHero__A533U > picture > img {
  object-fit: cover;
  width: 100%;
  height: 110%;
  position: absolute;
  z-index: -1000;
}
.header_imageOverlay__3ufPJ {
  background: rgba(35, 164, 206, 0.8);
  position: absolute;
  z-index: -10;
}
.header_headerInfo__3cDFM {
  display: grid;
  max-width: 75%;
  height: 75%;
  grid-template-columns: 0.15fr 0.85fr;
  z-index: 10;
  color: white;
  align-items: center;
  margin: 0 auto;
  padding: 75px 0;
}
.header_headerInfo__3cDFM h1:first-of-type {
  font-size: 5rem;
  line-height: 80px;
  font-weight: 800;
  border-right: 2px solid rgb(var(--orange));
}
.header_about__2JMHN {
  margin-left: 50px;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 2px;
}
.header_white__3QM9o {
  background: white;
  width: 100%;
  height: 75vh;
  -webkit-transform: skew(0deg, -3deg);
          transform: skew(0deg, -3deg);
  position: relative;
  top: -5vh;
}
.header_work__21FdZ {
  font-size: 0.9rem;
  border: 1px solid rgba(var(--orange), 0.8);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 10px 25px;
  letter-spacing: 2px;
  font-weight: 500;
  background: transparent;
  /* box-shadow:0 0 10px rgba(203, 92, 55, .8); */
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.header_work__21FdZ:hover {
  box-shadow: 0 0 10px rgba(203, 92, 55, 0.8);
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .header_headerInfo__3cDFM {
    max-width: 90%;
  }
  .header_headerInfo__3cDFM h1:first-of-type {
    font-size: 4.25rem;
    line-height: 65px;
  }
  .header_headerInfo__3cDFM :nth-child(2) {
    font-size: 1.1rem;
  }
  .header_work__21FdZ {
    font-size: 0.7rem;
    margin: 50px 0;
    padding: 8px 20px;
  }
}
@media (max-width: 1200px) {
  .header_headerInfo__3cDFM {
    max-width: 90%;
    height: 75%;
    grid-template-columns: 1fr;
    margin: 0 auto;
    padding: 40px 0;
  }
  .header_headerInfo__3cDFM h1:first-of-type {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: 1.75rem;
    line-height: 20px;
    font-weight: 800;
    border-right: none;
  }
  .header_headerInfo__3cDFM :nth-child(2) {
    margin: 0 0 40px 0;
    font-size: 0.9rem;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: 2px;
  }
}

.us_us__3czcw{
 position: relative;
}
.us_white__1MdU4{
 z-index: -1;
 background:white;
 width: 100%;
 height: 75vh;
 -webkit-transform: skew(0deg, -3deg);
         transform: skew(0deg, -3deg);
 position: absolute;
 top: -5vh;
}
.us_container__1vce1{
 max-width: 75%;
 margin: 0 auto;
 padding-top: 50px;
}
.us_usHeader__3i4sL{
 max-width: -webkit-max-content;
 max-width: -moz-max-content;
 max-width: max-content;
 color: rgb(120, 120, 120);
 font-size: 1.5rem;
 letter-spacing: 2px;
 font-weight: 800;
 /* animation: dissolve .5s ease-in-out; */
}

@media(max-width: 1600px){
 .us_white__1MdU4{
  -webkit-transform: skew(0deg, -6deg);
          transform: skew(0deg, -6deg);
 }
 .us_usHeader__3i4sL{
  font-size: 1.2rem;
 }
 .us_container__1vce1{
  max-width: 90%;
 }
}

/* @keyframes dissolve {
    0% {
      opacity: 0;
      padding-top: 20px;
    }
    100% {
      opacity: 1;
      padding-top: 0px;
    }
  } */
.category_details__2eLqy{
 background: transparent;
 display: grid;
 grid-gap: 75px;
 grid-template-columns: repeat(5,1fr);
}
.category_catTitle__3Kz0V{
 letter-spacing: 1px;
 font-size: 1.25rem;
}
.category_catDesc__sbXZR{
 letter-spacing: 2px;
 line-height: 25px;
 font-size: 1rem;
}
ul{
 padding: 0;
}
li{
 list-style: none;
 padding: .5rem 0;
 letter-spacing: 1px;
 font-size: 1.1rem;
}
@media(min-width: 1200px) and (max-width: 1600px){
 .category_details__2eLqy{
  grid-gap: 20px;
 }
}
@media(min-width: 992px) and (max-width: 1200px){
 .category_details__2eLqy{
  grid-gap: 15px;
 }
 .category_catTitle__3Kz0V{
  padding-bottom: 5px;
 }
}
@media(max-width: 992px){
 .category_details__2eLqy{
  grid-template-columns: 1fr;
 }
 .category_catDesc__sbXZR{
  line-height: 20px;
 }
}
.work_work__2_Z2F{
 position: relative;
 height: 100%;
}
.work_blue__3UKKo{
 height: 100%;
 z-index: -1;
 background: rgb(var(--blue));
 width: 100%;
 -webkit-transform: skew(0deg, -3deg);
         transform: skew(0deg, -3deg);
 position: absolute;
}
.work_container__1ulGX{
 max-width: 75%;
 margin: 0 auto;
 padding-top: 100px;
}
.work_workHeader__3_8yS{
 float: right;
 max-width: -webkit-max-content;
 max-width: -moz-max-content;
 max-width: max-content;
 color: rgb(var(--darkBlue));
 font-size: 1.5rem;
 letter-spacing: 2px;
 font-weight: 800;
}
.work_workDescr__2NpY9{
 letter-spacing: 2px;
 line-height: 30px;
 text-align: end;
 padding: 15px 0 150px 0;
 margin: 0;
 width: 75%;
}


@media(max-width: 1600px){
 .work_blue__3UKKo{
  -webkit-transform: skew(0deg, -6deg);
          transform: skew(0deg, -6deg);
 }
 .work_workHeader__3_8yS{
  font-size: 1.2rem;
 }
 .work_workDescr__2NpY9{
  width: 100%;
 }
 .work_container__1ulGX{
  max-width: 90%;
 }
}
.workImg_imgGrid__37EUJ {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(10px, 275px);
  grid-gap: 10px;
}
.workImg_imgBlock__UeYtr {
  object-fit: cover;
  height: 275px;
  width: 100%;
  cursor: pointer;
  /* opacity: .5; */
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.workImg_imgBlock__UeYtr:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
picture {
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  overflow: hidden;
}
picture.workImg_workImg__1cXAg:hover {
  box-shadow: 0 0 15px rgb(var(--darkBlue));
}
picture.workImg_workImg__1cXAg:after {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  background: transparent;
  position: absolute;
  z-index: -1;
}
.workImg_shadowImage__1wCWG {
  object-fit: contain;
  box-shadow: 0 0 30px rgb(var(--darkBlue));
  height: 50%;
}
.workImg_shadowContainer__-TFLj {
  display: contents;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .workImg_imgGrid__37EUJ {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(auto, 200px);
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .workImg_imgGrid__37EUJ {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(10px, 200px);
  }
  .workImg_shadowImage__1wCWG {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
}
@media (max-width: 768px) {
  .workImg_imgGrid__37EUJ {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(10px, 200px);
  }
  .workImg_shadowImage__1wCWG {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
  .workImg_imgBlock__UeYtr {
    opacity: 1;
  }
  .workImg_imgBlock__UeYtr:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.addItem_addItem__3ZteT{
 width: 50%;
 padding-bottom: 3rem;
 line-height: 30px;
 font-size: 1.1rem;
}
.addItem_itemTitle__dKVwe{
 color: rgb(var(--darkBlue));
 font-size: 1.25rem;
 letter-spacing: 1px;
}
.addItem_itemDesc__2nrjX{
 padding-bottom: 1.5rem;
}

@media(min-width: 1200px) and (max-width: 1600px){
 .addItem_addItem__3ZteT{

 }
}
@media(min-width: 768px) and (max-width: 1200px){
 .addItem_addItem__3ZteT{
  width: 100%;
 }
}
@media(max-width: 768px){
 .addItem_addItem__3ZteT{
  width: 100%;
 }
}
.contact_contactForm__3M7z2{
    margin: 0 auto;
 display: grid;
 grid-template-columns: 1fr;
 grid-gap: 75px;
 width: 50%;
}
.contact_contactItem__3an7E{
 display: flex;
 flex-direction: column;
}
.contact_contactItem__3an7E:last-child{
 /* align-items: flex-end; */
}
.contact_contactTitle__1muOE{
font-size: 1.25rem;
 letter-spacing: 1px;
 display: flex;
 flex-direction: column;
 width: -webkit-max-content;
 width: -moz-max-content;
 width: max-content;
}
.contact_contactItem__3an7E:last-child > .contact_contactTitle__1muOE:first-child{
 /* align-items: flex-end; */
}
.contact_contactInfo__PISzG{
 font-size: 1.1rem;
 letter-spacing: 2px;
 width: -webkit-max-content;
 width: -moz-max-content;
 width: max-content;
}
@media(min-width: 1200px) and (max-width: 1600px){

}
@media(min-width: 992px) and (max-width: 1200px){

}
@media(max-width: 992px){
 .contact_contactItem__3an7E{
  align-items: flex-end;
 }
 .contact_contactItem__3an7E > .contact_contactTitle__1muOE:first-child{
  align-items: flex-end;
 }
 .contact_contactForm__3M7z2{
  grid-template-columns: 1fr;
  grid-gap: 50px;
 }
}
.footer_footer__13GXH {
  border-top: 1px solid rgb(var(--lightGrey));
  margin-top: 25vh;
  height: auto;
  /* background: rgb(var(--blue)); */
  padding: 20px;
  justify-content: center;
  align-items: flex-end;
}
.footer_footerItem__1dlM9 {
}
.footer_footerItemTitle__--ONZ {
  padding: 2rem 0 1rem 0;
}
.footer_footerList__2lXCm > li {
  padding: 0.25rem 0;
  font-size: 0.8rem;
}
.footer_footerList__2lXCm {
  margin: 0;
}
.footer_footerCats__1gP3f {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 50%;
  margin: 0 auto;
}
.footer_copyright__3hUlR {
  padding-top: 5rem;
  width: 100%;
  text-align: center;
}

@media (min-width: 1200px) and (max-width: 1600px) {
}
@media (min-width: 992px) and (max-width: 1200px) {
}
@media (max-width: 992px) {
  .footer_footerCats__1gP3f {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    grid-gap: 25px;
  }
  .footer_footerItemTitle__--ONZ {
    font-size: 0.75rem;
  }
  .footer_footerList__2lXCm > li {
    font-size: 0.65rem;
    padding: 0.25rem 0;
  }
  .footer_copyright__3hUlR {
    font-size: 0.75rem;
  }
}

