.imgGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(10px, 275px);
  grid-gap: 10px;
}
.imgBlock {
  object-fit: cover;
  height: 275px;
  width: 100%;
  cursor: pointer;
  /* opacity: .5; */
  transition: all 0.15s ease-in-out;
}
.imgBlock:hover {
  transform: scale(1.1);
}
picture {
  transition: all 0.15s ease-in-out;
  overflow: hidden;
}
picture.workImg:hover {
  box-shadow: 0 0 15px rgb(var(--darkBlue));
}
picture.workImg:after {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  background: transparent;
  position: absolute;
  z-index: -1;
}
.shadowImage {
  object-fit: contain;
  box-shadow: 0 0 30px rgb(var(--darkBlue));
  height: 50%;
}
.shadowContainer {
  display: contents;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .imgGrid {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(auto, 200px);
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .imgGrid {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(10px, 200px);
  }
  .shadowImage {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
}
@media (max-width: 768px) {
  .imgGrid {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(10px, 200px);
  }
  .shadowImage {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
  .imgBlock {
    opacity: 1;
  }
  .imgBlock:hover {
    transform: scale(1);
  }
}
