.contactForm{
    margin: 0 auto;
 display: grid;
 grid-template-columns: 1fr;
 grid-gap: 75px;
 width: 50%;
}
.contactItem{
 display: flex;
 flex-direction: column;
}
.contactItem:last-child{
 /* align-items: flex-end; */
}
.contactTitle{
font-size: 1.25rem;
 letter-spacing: 1px;
 display: flex;
 flex-direction: column;
 width: max-content;
}
.contactItem:last-child > .contactTitle:first-child{
 /* align-items: flex-end; */
}
.contactInfo{
 font-size: 1.1rem;
 letter-spacing: 2px;
 width: max-content;
}
@media(min-width: 1200px) and (max-width: 1600px){

}
@media(min-width: 992px) and (max-width: 1200px){

}
@media(max-width: 992px){
 .contactItem{
  align-items: flex-end;
 }
 .contactItem > .contactTitle:first-child{
  align-items: flex-end;
 }
 .contactForm{
  grid-template-columns: 1fr;
  grid-gap: 50px;
 }
}