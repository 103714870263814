.us{
 position: relative;
}
.white{
 z-index: -1;
 background:white;
 width: 100%;
 height: 75vh;
 transform: skew(0deg, -3deg);
 position: absolute;
 top: -5vh;
}
.container{
 max-width: 75%;
 margin: 0 auto;
 padding-top: 50px;
}
.usHeader{
 max-width: max-content;
 color: rgb(120, 120, 120);
 font-size: 1.5rem;
 letter-spacing: 2px;
 font-weight: 800;
 /* animation: dissolve .5s ease-in-out; */
}

@media(max-width: 1600px){
 .white{
  transform: skew(0deg, -6deg);
 }
 .usHeader{
  font-size: 1.2rem;
 }
 .container{
  max-width: 90%;
 }
}

/* @keyframes dissolve {
    0% {
      opacity: 0;
      padding-top: 20px;
    }
    100% {
      opacity: 1;
      padding-top: 0px;
    }
  } */