.footer {
  border-top: 1px solid rgb(var(--lightGrey));
  margin-top: 25vh;
  height: auto;
  /* background: rgb(var(--blue)); */
  padding: 20px;
  justify-content: center;
  align-items: flex-end;
}
.footerItem {
}
.footerItemTitle {
  padding: 2rem 0 1rem 0;
}
.footerList > li {
  padding: 0.25rem 0;
  font-size: 0.8rem;
}
.footerList {
  margin: 0;
}
.footerCats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 50%;
  margin: 0 auto;
}
.copyright {
  padding-top: 5rem;
  width: 100%;
  text-align: center;
}

@media (min-width: 1200px) and (max-width: 1600px) {
}
@media (min-width: 992px) and (max-width: 1200px) {
}
@media (max-width: 992px) {
  .footerCats {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    grid-gap: 25px;
  }
  .footerItemTitle {
    font-size: 0.75rem;
  }
  .footerList > li {
    font-size: 0.65rem;
    padding: 0.25rem 0;
  }
  .copyright {
    font-size: 0.75rem;
  }
}
